import React from 'react';
import { useHistory } from 'react-router';
import logoBanner from '../images/snapshot-logo-banner.png';
import steamLogo from '../images/steam_logo.svg';
import metaQuestIcon from '../images/meta-quest-icon.png';
import discordIcon from '../images/discord-icon.png';
import avatarBlueHead from '../images/bluehead.png';
import { usePlayfabAuthContext } from '../contexts/PlayfabAuthContext';
import { Link } from 'react-router-dom';

export const Navbar: React.FC = () => {
  const playfab = usePlayfabAuthContext();
  const history = useHistory();

  const home = () => history.push('/');

  const logout = () => {
    if (window.confirm('Logout. Are you sure?')) {
      playfab.api.logout();
      history.push('/');
    }
  };

  return (
    <div className="p-2 flex flex-col md:flex-row gap-2 bg-[rgba(0,0,0,0.55)] text-sm">
      <div className="flex flex-col gap-2 grow">
        <div className="flex flex-col md:flex-row gap-2">
          <span onClick={home} className="">
            <img alt="Snapshot VR" src={logoBanner} className="h-[2rem]" />
          </span>
        </div>
        <div className="flex flex-row gap-2 items-center flex-wrap">
          <a
            className=""
            href="https://store.steampowered.com/app/1133580/Snapshot_VR/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={steamLogo} alt="Steam" className="h-[2rem]" />
          </a>
          <a
            className=""
            href="https://www.oculus.com/experiences/quest/6612622932096815?utm_source=snapshotvr.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={metaQuestIcon} alt="Meta Quest" className="h-[2rem]" />
          </a>
          <a
            className=""
            href="https://discord.gg/GFNuRaDkfu"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={discordIcon} alt="Discord" className="h-[2rem]" />
          </a>
          <a
            className="p-2 rounded-lg bg-[#0095da] font-bold"
            href="https://giantscam.bigcartel.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            SHOP
          </a>
        </div>
      </div>
      {playfab.isLoggedIn && (
        <div className="p-2 min-w-[200px] flex flex-row gap-2 bg-[#231f20] rounded-xl">
          <img
            alt="player profile"
            src={
              `${
                playfab.profile?.AvatarUrl
              }?r=${playfab.avatarLastUpdatedAt?.toTimeString()}` ||
              avatarBlueHead
            }
            className="h-[64px]"
          />
          <div className="grow flex flex-col">
            <span className="text-lg">
              {playfab.profile?.DisplayName || 'Your Name Here'}
            </span>
            <div className="flex flex-row">
              <span className="grow">Level</span>
              {playfab.stats?.level}
            </div>
            <progress
              max={1}
              value={playfab.levelProgress}
              className="w-full"
            />
          </div>
        </div>
      )}
      <div className="flex flex-col">
        {playfab.isLoggedIn ? (
          <button
            onClick={logout}
            className="px-2 py-1 rounded-lg bg-[#823] cursor-pointer font-bold"
          >
            Logout
          </button>
        ) : (
          <Link to="/login" className="p-2 rounded-lg bg-[#0095da] font-bold">
            Login
          </Link>
        )}
      </div>
    </div>
  );
};
