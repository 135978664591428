import { PlayFabClient } from 'playfab-sdk';
import React, { useEffect, useState } from 'react';
import {
  getSeasonalStatsFromProfile,
  LeaderboarData,
  ProfileConstraints,
} from '../lib/playfab';

export const SeasonLeaderboard: React.FC = () => {
  const [busy, setBusy] = useState<boolean>(false);
  const [leaderboardData, setLeaderboardData] = useState<LeaderboarData>();

  useEffect(() => {
    if (!leaderboardData) {
      setBusy(true);
      PlayFabClient.GetLeaderboard(
        {
          StartPosition: 0,
          StatisticName: 'Kills (Seasonal)',
          ProfileConstraints,
        },
        (error, result) => {
          if (error) {
            console.error('failed to load leaderboard', error);
            alert(`Could not load leaderboard\n\n${error.errorMessage}`);
            setBusy(false);
            return;
          }
          setLeaderboardData(result.data.Leaderboard);
          setBusy(false);
        }
      );
    }
  }, [leaderboardData]);

  if (busy) {
    return (
      <div className="p-2 rounded-xl bg-white text-black">
        <progress />
      </div>
    );
  }

  return (
    <div className="p-2 rounded-xl bg-white text-black">
      <p className="text-xl font-bold text-[#aaa] italic">Season Leaderboard</p>
      <table className="leaderboard">
        <thead>
          <tr>
            <th>Player</th>
            <th>Kills</th>
            <th>Deaths</th>
            <th>Matches</th>
            <th>K/D</th>
            <th>K/M</th>
          </tr>
        </thead>
        <tbody>
          {leaderboardData?.map((item) => (
            <SeasonalLeaderboardRow {...item} key={item.PlayFabId} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const SeasonalLeaderboardRow = ({
  DisplayName,
  Profile,
}: PlayFabClientModels.PlayerLeaderboardEntry) => {
  const stats = Profile ? getSeasonalStatsFromProfile(Profile) : undefined;
  const kdRatio =
    stats?.kills && stats?.deaths ? stats.kills / stats.deaths : 0;
  const kmRatio =
    stats?.kills && stats?.matchesPlayed
      ? stats.kills / stats.matchesPlayed
      : 0;
  return (
    <tr>
      <th>{DisplayName}</th>
      <td>{stats?.kills || 0}</td>
      <td>{stats?.deaths || 0}</td>
      <td>{stats?.matchesPlayed || 0}</td>
      <td>{kdRatio.toFixed(2)}</td>
      <td>{kmRatio.toFixed(2)}</td>
    </tr>
  );
};
