import React, { useState } from 'react';
import { usePlayfabAuthContext } from '../contexts/PlayfabAuthContext';
import { Redirect } from 'react-router';
import { PlayFabClient } from 'playfab-sdk';
import { TitleId } from '../lib/playfab';

export const LoginForm: React.FC = () => {
  const playfab = usePlayfabAuthContext();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault();
    await playfab.api.login(email, password);
    setPassword('');
  };

  const handleReset: React.MouseEventHandler = async (e) => {
    e.preventDefault();
    PlayFabClient.SendAccountRecoveryEmail(
      {
        Email: email,
        TitleId,
      },
      (error, result) => {
        if (error) {
          console.error('error sending account recovery link', error);
          const message = error?.errorDetails
            ? Object.values(error.errorDetails as Record<string, string[]>)
                .map((x) => (x ? x[0] : ''))
                .reduce((a, b) => `${a}\n${b}`)
            : error?.errorMessage;
          alert(message);
        } else {
          alert('check your email');
        }
      }
    );
    setPassword('');
  };

  if (playfab.isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div className="m-[2rem] md:m-[3rem] flex flex-col items-center gap-2">
      <form
        onSubmit={handleSubmit}
        className="flex flex-row gap-2 justify-center"
      >
        <div className="flex flex-col gap-2">
          <input
            type="text"
            placeholder="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="px-4 py-2 bg-white rounded-full text-black"
          />
          <input
            type="password"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="px-4 py-2 bg-white rounded-full text-black"
          />
        </div>
        <button
          type="submit"
          className="px-4 py-2 bg-[#0095da] rounded-2xl text-white font-bold cursor-pointer"
        >
          login
        </button>
      </form>
      <button className="px-2 py-1 bg-[#666] rounded-xl" onClick={handleReset}>
        send password reset link
      </button>
    </div>
  );
};
