import { PlayFabClient, PlayFabGroups } from 'playfab-sdk';

const DEFAULT_TITLEID = 'BB431';
const { PLAYFAB_TITLE_ID } = process.env;

export const TitleId = PLAYFAB_TITLE_ID || DEFAULT_TITLEID;

export type TitleData = {
  StaffGroupId: string;
  TrustedGroupId: string;
};

export type PlayerStats = {
  kills: number;
  deaths: number;
  level: number;
  matchesPlayed: number;
  xp: number;
};

export type LeaderboarData =
  | PlayFabClientModels.PlayerLeaderboardEntry[]
  | undefined;

export const ProfileConstraints = {
  ShowAvatarUrl: true,
  ShowBannedUntil: false,
  ShowCampaignAttributions: false,
  ShowContactEmailAddresses: false,
  ShowCreated: true,
  ShowDisplayName: true,
  ShowExperimentVariants: false,
  ShowLastLogin: true,
  ShowLinkedAccounts: false,
  ShowLocations: false,
  ShowMemberships: false,
  ShowOrigination: false,
  ShowPushNotificationRegistrations: false,
  ShowStatistics: true,
  ShowTags: true,
  ShowTotalValueToDateInUsd: false,
  ShowValuesToDate: false,
};

const pullStat = (
  profile: PlayFabClientModels.PlayerProfileModel,
  name: string
) => profile?.Statistics?.find((x) => x.Name === name)?.Value || 0;

export const getAllTimeStatsFromProfile = (
  profile: PlayFabClientModels.PlayerProfileModel
) => ({
  kills: pullStat(profile, 'Kills (All Time)'),
  deaths: pullStat(profile, 'Deaths (All Time)'),
  level: pullStat(profile, 'Level (All Time)'),
  matchesPlayed: pullStat(profile, 'Matches Played (All Time)'),
  xp: pullStat(profile, 'XP (All Time)'),
});

export const getSeasonalStatsFromProfile = (
  profile: PlayFabClientModels.PlayerProfileModel
) => ({
  kills: pullStat(profile, 'Kills (Seasonal)'),
  deaths: pullStat(profile, 'Deaths (Seasonal)'),
  level: pullStat(profile, 'Level (Seasonal)'),
  matchesPlayed: pullStat(profile, 'Matches Played (Seasonal)'),
  xp: pullStat(profile, 'XP (Seasonal)'),
});

export const getRawLevelFromXp = (xp: number) => {
  const averageXp = 1000;
  const levelingCurveModifier = 1.8;
  return Math.pow(xp / Number(averageXp), 1 / Number(levelingCurveModifier));
};

export const getLevelProgressFromXp = (xp: number) => {
  const rawLevel = getRawLevelFromXp(xp);
  return rawLevel % 1;
};

export const getIsTrusted = async (
  EntityKey: PlayFabClientModels.EntityKey | undefined
) => {
  if (EntityKey === undefined) {
    return false;
  }
  return new Promise<boolean>(async (resolve, reject) => {
    const titleData: TitleData = await new Promise((resolve, reject) =>
      PlayFabClient.GetTitleData({}, (error, result) => {
        if (error) {
          console.error('getIsTrusted failed to load title data', error);
          reject(error);
        }
        resolve(result.data.Data as unknown as TitleData);
      })
    );
    const groupId = titleData.TrustedGroupId;
    PlayFabGroups.IsMember(
      {
        Entity: EntityKey,
        Group: { Id: titleData.TrustedGroupId, Type: 'group' },
      },
      (error, result) => {
        if (error) {
          console.error(
            `getIsTrusted failed to load is Member (group Id: ${groupId})`,
            error
          );
          resolve(false);
        }
        const isMember = result?.data?.IsMember;
        resolve(isMember);
      }
    );
  });
};
