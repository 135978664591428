import React from 'react';
import steamLogo from '../images/steam_logo.svg';
import metaQuestIcon from '../images/meta-quest-icon.png';
import vrmlIcon from '../images/vrml-icon.png';
import discordIcon from '../images/discord-icon.png';
import instagramIcon from '../images/instagram-icon.png';
import giantScamIcon from '../images/giant-scam-logo.png';

export const Footer: React.FC = () => {
  return (
    <div className="mt-[4rem] p-2 pb-[4rem] flex flex-col gap-2 bg-[rgba(0,0,0,0.55)]">
      <div className="p-4 grow flex flex-row gap-2 items-center justify-around flex-wrap">
        <a
          className=""
          href="https://store.steampowered.com/app/1133580/Snapshot_VR/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={steamLogo} alt="Steam" className="h-[2rem]" />
        </a>
        <a
          className=""
          href="https://www.oculus.com/experiences/quest/6612622932096815?utm_source=snapshotvr.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={metaQuestIcon} alt="Meta Quest" className="h-[2rem]" />
        </a>
        <a
          className=""
          href="https://vrmasterleague.com/Snapshot/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={vrmlIcon} alt="VRML" className="h-[2rem]" />
        </a>
        <a
          className="m-1"
          href="https://www.instagram.com/giantscam/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={instagramIcon} alt="Instagram" className="h-[2rem]" />
        </a>
        <a
          className=""
          href="https://discord.gg/GFNuRaDkfu"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={discordIcon} alt="Discord" className="h-[2rem]" />
        </a>
      </div>
      <div className="p-4 grow flex flex-row gap-2 items-center justify-around flex-wrap">
        <a
          className=""
          href="http://giantscam.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={giantScamIcon} alt="Giant Scam" className="h-[4rem]" />
        </a>
      </div>
      {/*
      <div className="grow flex flex-col items-center justify-center">
        <DiscordEmbed />
      </div>*/}
    </div>
  );
};
