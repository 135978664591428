import React, { useEffect, useState } from 'react';
import { usePlayfabAuthContext } from '../contexts/PlayfabAuthContext';
import { resizeImageFile } from '../lib/image';
import { getIsTrusted } from '../lib/playfab';

const VALID_FILETYPES = ['image/png', 'image/jpeg', 'image/jpg'];
const KB = 1000;
const MB = 1000 * KB;
const maxUploadSize = MB;
const AVATAR_SIZE = 512;

export const UserProfile: React.FC = () => {
  const playfab = usePlayfabAuthContext();
  const [busy, setBusy] = useState<boolean>(false);
  const [isTrusted, setIsTrusted] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      if (isTrusted === undefined) {
        setBusy(true);
        const newValue = await getIsTrusted(playfab.entityKey).catch((err) => {
          console.error('could not fetch data for UserProfile', err);
        });
        setIsTrusted(newValue || false);
        setBusy(false);
      }
    };
    fetchData();
  }, [busy, isTrusted, playfab.profile, playfab.entityKey]);

  const persistAvatar = async (image?: File) => {
    if (!image) return;
    setBusy(true);
    const imageBlob = await resizeImageFile(
      image,
      AVATAR_SIZE,
      AVATAR_SIZE
    ).catch((error) => {
      console.error('could not resize image', error);
    });

    if (!imageBlob) {
      return;
    }
    if (!VALID_FILETYPES.includes(imageBlob.type)) {
      alert('Only JPEG and PNG filetypes are supported.');
      setBusy(false);
      return;
    }
    if (imageBlob.size > maxUploadSize) {
      alert('Avatar images must be 1MB or smaller.');
      setBusy(false);
      return;
    }

    const sessionTicket = window.sessionStorage.getItem('sessionTicket');
    if (!sessionTicket) {
      alert('Not logged in');
      setBusy(false);
      return;
    }
    const getUploadUrl =
      'https://byai574q2mcct3lwryl2tisc2i0mpuvg.lambda-url.us-east-1.on.aws/';

    const uploadUrl = await fetch(getUploadUrl, {
      method: 'POST',
      headers: {
        'X-SessionTicket': sessionTicket,
        'X-Content-Type': imageBlob.type,
      },
    })
      .then(async (response) => {
        const json = await response.json();
        return (json as unknown as { uploadUrl: string }).uploadUrl;
      })
      .catch((error) => {
        console.error('failed to get upload url', error);
      });
    if (uploadUrl) {
      await fetch(uploadUrl, {
        body: imageBlob,
        method: 'PUT',
      }).then((x) => {
        // TODO: come back to this CDN version after figuring out cache expiry.
        //const imageUrl = `https://snapshotvr.com/avatars/${playfab.profile?.PlayerId}`;
        const imageUrl = `https://s3.amazonaws.com/snapshotvr/avatars/${playfab.profile?.PlayerId}`;
        playfab.api.setAvatarUrl(imageUrl);
      });
    } else {
      alert('failed to upload');
      console.error('failed to get uplaod url');
    }
    setBusy(false);
  };

  if (busy) {
    return <p>Loading...</p>;
  }

  return (
    <div className="flex flex-col gap-2 items-center">
      <div className="p-4 min-w-[400px] flex flex-row gap-2 justify-center bg-white text-black rounded-xl">
        <AvatarBox
          onUpload={persistAvatar}
          url={playfab.profile?.AvatarUrl || ''}
        />
        <div className="grow flex flex-col gap-1">
          <span className="text-2xl">{playfab.profile?.DisplayName}</span>
          {isTrusted && (
            <div className="rounded-full bg-[#0095da] font-bold text-white text-center">
              trusted
            </div>
          )}
          <p className="py-1 flex flex-row">
            <span className="grow">Level</span>
            {playfab.stats?.level}
          </p>
          <progress max={1} value={playfab.levelProgress} className="w-full" />
        </div>
      </div>
    </div>
  );
};

type AvatarBoxProps = { onUpload: (image?: File) => void; url: string };
const AvatarBox: React.FC<AvatarBoxProps> = (props) => {
  const inputRef: React.Ref<HTMLInputElement> = React.useRef(null);

  return (
    <div
      className="w-[128px] h-[128px] bg-cover flex flex-col justify-end items-center"
      style={{
        backgroundImage: `url(${props.url}?d=${new Date().getTime()})`,
      }}
    >
      <button
        className="m-2 p-2 text-xs rounded-full text-white bg-[rgba(0,0,0,0.2)] hover:bg-[rgba(0,0,0,0.5)] cursor-pointer"
        onClick={() => inputRef.current?.click()}
      >
        change
      </button>
      <input
        hidden
        ref={inputRef}
        name="avatar_url"
        type="file"
        onChange={(e) => props.onUpload(e.target.files?.[0])}
      />
    </div>
  );
};
