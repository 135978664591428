import React from 'react';
import { Route, Redirect, BrowserRouter, Switch } from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { PlayfabAuthContextProvider } from './contexts/PlayfabAuthContext';
import { Dashboard } from './routes/Dashboard';

export const App: React.FC = () => {
  return (
    <PlayfabAuthContextProvider>
      <BrowserRouter>
        <Navbar />
        <div className="grow flex flex-col">
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <Redirect to="/" />
          </Switch>
        </div>
        <Footer />
      </BrowserRouter>
    </PlayfabAuthContextProvider>
  );
};
