import React from 'react';
import { SeasonLeaderboard } from '../components/SeasonLeaderboard';
import { UserProfile } from '../components/UserProfile';
import { usePlayfabAuthContext } from '../contexts/PlayfabAuthContext';
import { LoginForm } from '../components/LoginForm';
import { TrailerVideoEmbed } from '../components/TrailerVideoEmbed';
import { StatsBox } from '../components/StatsBox';
import {
  getAllTimeStatsFromProfile,
  getSeasonalStatsFromProfile,
} from '../lib/playfab';
import { AllTimeLeaderboard } from '../components/AllTimeLeaderboard';

export const Dashboard: React.FC = () => {
  const playfab = usePlayfabAuthContext();

  if (!playfab.isLoggedIn) {
    return (
      <>
        <LoginForm />
        <TrailerVideoEmbed />
      </>
    );
  }

  return (
    <div className="p-4 flex flex-col gap-2">
      <UserProfile />

      <div className="flex flex-col md:flex-row gap-2 justify-center">
        {playfab.profile && (
          <StatsBox
            label="Season Stats"
            stats={getSeasonalStatsFromProfile(playfab.profile)}
          />
        )}
        <SeasonLeaderboard />
      </div>
      <div className="flex flex-col md:flex-row gap-2 justify-center">
        {playfab.profile && (
          <StatsBox
            label="All Time Stats"
            stats={getAllTimeStatsFromProfile(playfab.profile)}
          />
        )}
        <AllTimeLeaderboard />
      </div>
    </div>
  );
};
