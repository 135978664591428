import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PlayFab, PlayFabClient } from 'playfab-sdk';
import {
  getAllTimeStatsFromProfile,
  getLevelProgressFromXp,
  PlayerStats,
  ProfileConstraints,
  TitleId,
} from '../lib/playfab';

type PlayFabWithSettings = typeof PlayFab & {
  _internalSettings: {
    sessionTicket: string | null;
    entityToken: string | null | undefined;
  };
  settings: {
    titleId: string;
  };
};
(PlayFab as PlayFabWithSettings).settings.titleId = TitleId;

export type PlayfabClientApi = {
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => void;
  setAvatarUrl: (url: string) => Promise<boolean>;
};

export type PlayfabAuthContextType = {
  api: PlayfabClientApi;
  isLoggedIn?: boolean;
  entityKey?: PlayFabClientModels.EntityKey;
  profile?: PlayFabClientModels.PlayerProfileModel;
  stats?: PlayerStats;
  levelProgress?: number;
  avatarLastUpdatedAt?: Date;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const PlayfabAuthContext = React.createContext<PlayfabAuthContextType>(null!);

export const PlayfabAuthContextProvider: React.FC = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [entityKey, setEntityKey] = useState<PlayFabClientModels.EntityKey>();
  const [profile, setProfile] =
    useState<PlayFabClientModels.PlayerProfileModel>();
  const [stats, setStats] = useState<PlayerStats>();
  const [levelProgress, setLevelProgress] = useState(0);
  const [avatarLastUpdatedAt, setAvatarLastUpdatedAt] = useState<Date>();

  const clearSession = useCallback(() => {
    const internalSettings = (PlayFab as PlayFabWithSettings)._internalSettings;
    internalSettings.sessionTicket = null;
    internalSettings.entityToken = null;
    window.sessionStorage.removeItem('entityToken');
    window.sessionStorage.removeItem('sessionTicket');
    window.sessionStorage.removeItem('entityKey');
  }, []);

  const updateProfileData = useCallback(
    (profile?: PlayFabClientModels.PlayerProfileModel) => {
      if (!profile) {
        setStats(undefined);
        setLevelProgress(0);
        return;
      }

      const newStats = getAllTimeStatsFromProfile(profile);
      const newLevelProgress = newStats?.xp
        ? getLevelProgressFromXp(newStats.xp)
        : 0;
      setStats(newStats);
      setLevelProgress(newLevelProgress);
      setProfile(profile);
    },
    []
  );

  const login = useCallback<PlayfabClientApi['login']>(
    async (email, password) => {
      try {
        const result = await new Promise<PlayFabClientModels.LoginResult>(
          (resolve, reject) => {
            PlayFabClient.LoginWithEmailAddress(
              {
                Email: email,
                Password: password,
                InfoRequestParameters: {
                  GetPlayerProfile: true,
                  GetCharacterInventories: false,
                  GetCharacterList: false,
                  GetPlayerStatistics: false,
                  GetTitleData: false,
                  GetUserAccountInfo: false,
                  GetUserData: false,
                  GetUserInventory: false,
                  GetUserReadOnlyData: false,
                  GetUserVirtualCurrency: false,
                  ProfileConstraints,
                },
              },
              (error, result) => (error ? reject(error) : resolve(result.data))
            );
          }
        );

        const sessionTicket = result.SessionTicket;
        const entityToken = result.EntityToken?.EntityToken;
        const entity = result.EntityToken?.Entity;

        if (!sessionTicket || !entityToken || !entity) {
          throw new Error('Invalid login response');
        }

        // Update PlayFab internal state
        const internalSettings = (PlayFab as PlayFabWithSettings)
          ._internalSettings;
        internalSettings.sessionTicket = sessionTicket;
        internalSettings.entityToken = entityToken;

        // Persist session
        window.sessionStorage.setItem('sessionTicket', sessionTicket);
        window.sessionStorage.setItem('entityToken', entityToken);
        window.sessionStorage.setItem('entityKey', JSON.stringify(entity));

        // Update application state
        setIsLoggedIn(true);
        setEntityKey(entity);
        updateProfileData(result.InfoResultPayload?.PlayerProfile);
        return true;
      } catch (error) {
        const e = error as PlayFabModule.IPlayFabError;
        const message = e?.errorDetails
          ? Object.values(e.errorDetails as Record<string, string[]>)
              .map((x) => (x ? x[0] : ''))
              .reduce((a, b) => `${a}\n${b}`)
          : e?.errorMessage || error;
        alert(`Could not log in:\n${message}`);
        return false;
      }
    },
    [updateProfileData]
  );

  const logout = useCallback(() => {
    clearSession();
    setIsLoggedIn(false);
    setEntityKey(undefined);
    updateProfileData(undefined);
  }, [clearSession, updateProfileData]);

  const setAvatarUrl = useCallback<PlayfabClientApi['setAvatarUrl']>(
    async (url) => {
      try {
        await new Promise((resolve, reject) => {
          PlayFabClient.UpdateAvatarUrl({ ImageUrl: url }, (error, result) =>
            error ? reject(error) : resolve(result)
          );
        });

        setProfile((prev) => (prev ? { ...prev, AvatarUrl: url } : undefined));
        setAvatarLastUpdatedAt(new Date());
        return true;
      } catch (error) {
        console.error('Failed to update avatar URL:', error);
        return false;
      }
    },
    []
  );

  const reactivateSession = useCallback(async () => {
    const sessionTicket = window.sessionStorage.getItem('sessionTicket');
    const entityToken = window.sessionStorage.getItem('entityToken');
    const storedEntityKey = window.sessionStorage.getItem('entityKey');

    if (!sessionTicket || !entityToken || !storedEntityKey) {
      clearSession();
      return;
    }

    try {
      const internalSettings = (PlayFab as PlayFabWithSettings)
        ._internalSettings;
      internalSettings.sessionTicket = sessionTicket;
      internalSettings.entityToken = entityToken;

      const entity = JSON.parse(storedEntityKey);
      const profileResult =
        await new Promise<PlayFabClientModels.GetPlayerProfileResult>(
          (resolve, reject) => {
            PlayFabClient.GetPlayerProfile(
              { ProfileConstraints },
              (error, result) => (error ? reject(error) : resolve(result.data))
            );
          }
        );

      console.log('Reactivated Session');
      setIsLoggedIn(true);
      setEntityKey(entity);
      updateProfileData(profileResult.PlayerProfile);
      setAvatarLastUpdatedAt(new Date());
    } catch (error) {
      console.error('Session reactivation failed:', error);
      clearSession();
    }
  }, [clearSession, updateProfileData]);

  useEffect(() => {
    const sessionExists = window.sessionStorage.getItem('sessionTicket');
    if (sessionExists && !isLoggedIn) {
      reactivateSession();
    }
  }, [isLoggedIn, reactivateSession]);

  const api = useMemo<PlayfabClientApi>(
    () => ({
      login,
      logout,
      setAvatarUrl,
    }),
    [login, logout, setAvatarUrl]
  );

  const contextValue = useMemo(
    () => ({
      api,
      isLoggedIn,
      entityKey,
      profile,
      stats,
      levelProgress,
      avatarLastUpdatedAt,
    }),
    [
      api,
      isLoggedIn,
      entityKey,
      profile,
      stats,
      levelProgress,
      avatarLastUpdatedAt,
    ]
  );

  return (
    <PlayfabAuthContext.Provider value={contextValue}>
      {children}
    </PlayfabAuthContext.Provider>
  );
};

export const usePlayfabAuthContext = () => React.useContext(PlayfabAuthContext);
