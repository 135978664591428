export const resizeImageFile = (
  inputFile: File,
  width: number,
  height: number
): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');
    const img = document.createElement('img');
    img.onload = () => {
      context?.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        0,
        0,
        canvas.width,
        canvas.height
      );
      canvas.toBlob((blob) =>
        blob !== null ? resolve(blob) : reject('Could not resize image')
      );
    };
    const reader = new FileReader();
    reader.onload = () => (img.src = reader.result as string);
    reader.readAsDataURL(inputFile);
  });
};
