import React from 'react';
import { PlayerStats } from '../lib/playfab';

type StatsBoxProps = { label: string; stats: PlayerStats | null };
export const StatsBox: React.FC<StatsBoxProps> = ({ label, stats }) => {
  const kdRatio =
    stats?.kills && stats?.deaths ? stats.kills / stats.deaths : 0;
  const kmRatio =
    stats?.kills && stats?.matchesPlayed
      ? stats.kills / stats.matchesPlayed
      : 0;
  //const levelProgress = stats?.xp ? getLevelProgressFromXp(stats.xp) : 0;
  return (
    <div className="p-2 rounded-lg flex flex-col bg-white text-black">
      <p className="text-xl font-bold text-[#aaa] italic">{label}</p>
      {/*
      <p className="py-1 flex flex-row">
        <span className="grow">Level</span>
        {stats?.level}
      </p>
      <progress max={1} value={levelProgress} className="w-full" />
      */}
      <p className="py-1 flex flex-row">
        <span className="grow">Kills</span>
        {stats?.kills}
      </p>
      <hr />
      <p className="py-1 flex flex-row">
        <span className="grow">Deaths</span>
        {stats?.deaths}
      </p>
      <hr />
      <p className="py-1 flex flex-row">
        <span className="grow">Matches</span>
        {stats?.matchesPlayed}
      </p>
      <hr />
      <p className="py-1 flex flex-row">
        <span className="grow">K/D</span>
        {kdRatio.toFixed(2)}
      </p>
      <hr />
      <p className="py-1 flex flex-row">
        <span className="grow">K/M</span>
        {kmRatio.toFixed(2)}
      </p>
    </div>
  );
};
